
import { defineComponent, reactive, ref, watch } from "vue";
import BasicInformation from "./components/BasicInformation.vue";
import FacilitiesAndServices from "./components/FacilitiesAndServices.vue";
import FacilitySettings from "./components/FacilitySettings.vue";
import SpaceQRCode from "./components/SpaceQRCode.vue";
import localstorage from "@/core/services/LocalstorageService";
import { updateSpace } from "@/core/services/api/spaces";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getSpacesByManager,
  updateFacilitySetting,
} from "@/core/services/api/spaces";

export default defineComponent({
  components: {
    BasicInformation,
    FacilitiesAndServices,
    FacilitySettings,
    SpaceQRCode,
  },
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let isEdit = ref(false);
    const spaceData: any = reactive({});

    const setSpaceData = () => {
      const currentSpace = localstorage.getCurrentSpaces()?.space;
      Object.assign(spaceData, currentSpace);
    };

    const changeData = (data) => {
      Object.assign(spaceData, data);
    };

    const update = async () => {
      await Swal.fire({
        title: "您確定要提交修改嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let facilitySettingRequest = {
            space: spaceData.guid,
            facilitySetting: spaceData.facilitySetting,
          };
          await updateFacilitySetting(facilitySettingRequest);
          let updateSpaceRequest = {
            guid: spaceData.guid,
            name: spaceData.name,
            status: spaceData.status,
            images: spaceData.theme.images,
            contact: {
              phones: spaceData.contact.phones,
              emails: spaceData.contact.emails,
              website: spaceData.contact.website,
              address: spaceData.contact.address,
              googleMap: spaceData.contact.googleMap,
            },
            socialMedia: {
              facebook: spaceData.socialMedia.facebook,
              line: spaceData.socialMedia.line,
            },
            information: spaceData.information,
            description: spaceData.description,
            openingHoursDescription: spaceData.openingHoursDescription,
            usageNote: spaceData.usageNote,
          };
          await updateSpace(updateSpaceRequest).then(() => {
            getSpacesByManager().then((spaces) => {
              localstorage.saveSpaces(spaces);
              let currentSpaces: any = spaces.filter(
                (o) => o.space.guid == currentSpaceGuid!
              );
              localstorage.saveCurrentSpaces(currentSpaces[0]);
              Swal.fire("提交成功!", "", "success");
            });
          });
        }
      });
    };

    const init = () => {
      setSpaceData();
    };
    init();

    return {
      isEdit,
      changeData,
      spaceData,
      update,
    };
  },
});
