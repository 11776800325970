
import { defineComponent, onMounted, reactive, ref } from "vue";
import draggable from "vuedraggable";
import localstorage from "@/core/services/LocalstorageService";
import { getIconPath } from "@/core/utils/imagePath";
import AddFacilitiesAndServices from "./AddFacilitiesAndServices.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    draggable,
    AddFacilitiesAndServices,
  },
  props: ["currentSpaceData", "isEdit"],
  setup(props) {
    let addFacilitiesAndServicesModal;
    let spaceData: any = reactive({});
    const setSpaceData = () => {
      const currentSpace = localstorage.getCurrentSpaces()?.space;
      Object.assign(spaceData, currentSpace);
    };

    const getIcon = (name) => {
      return getIconPath(name);
    };

    const deleteFacility = (number) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.information.splice(number, 1);
    };

    const addFacilitiesAndServices = (formData) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.information.push(JSON.parse(JSON.stringify(formData)));
      addFacilitiesAndServicesModal.hide();
    };

    onMounted(() => {
      addFacilitiesAndServicesModal = new Modal(
        document.getElementById("addFacilitiesAndServices-modal")
      );
    });

    const init = () => {
      setSpaceData();
    };
    init();

    return {
      spaceData,
      getIcon,
      deleteFacility,
      props,
      addFacilitiesAndServices,
    };
  },
});
