<template>
  <el-form
    id="kt_modal_additional_orders_list"
    :model="props.currentSpaceData"
    ref="formRef"
    class="form"
    label-width="110px"
  >
    <el-form-item label="設施類型">
      <el-tag
        class="me-1"
        :key="tag"
        v-for="tag in props.currentSpaceData.facilitySetting.facilityCategories"
        :closable="props.isEdit"
        :disable-transitions="false"
        @close="handleCloseFacilityCategories(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisibleFacilityCategories"
        v-model="inputValueFacilityCategories"
        ref="saveTagInputFacilityCategories"
        size="small"
        @keyup.enter="handleInputConfirmFacilityCategories"
        @blur="handleInputConfirmFacilityCategories"
      >
      </el-input>
      <el-button
        v-else-if="props.isEdit"
        class="button-new-tag"
        size="small"
        @click="showInputFacilityCategories"
        >+</el-button
      >
    </el-form-item>
    <el-form-item label="設備類型">
      <el-tag
        class="me-1"
        :key="tag"
        v-for="tag in props.currentSpaceData.facilitySetting.propertyCategories"
        :closable="props.isEdit"
        :disable-transitions="false"
        @close="handleClosePropertyCategories(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisiblePropertyCategories"
        v-model="inputValuePropertyCategories"
        ref="saveTagInputPropertyCategories"
        size="small"
        @keyup.enter="handleInputConfirmPropertyCategories"
        @blur="handleInputConfirmPropertyCategories"
      >
      </el-input>
      <el-button
        v-else-if="props.isEdit"
        class="button-new-tag"
        size="small"
        @click="showInputPropertyCategories"
        >+</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, nextTick, ref } from "vue";

export default defineComponent({
  props: ["currentSpaceData", "isEdit"],
  setup(props) {
    let inputVisibleFacilityCategories = ref(false);
    let inputValueFacilityCategories = ref("");
    const saveTagInputFacilityCategories: any = ref(null);

    const handleCloseFacilityCategories = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData;
      propsCurrentSpaceData.facilitySetting.facilityCategories.splice(
        propsCurrentSpaceData.facilitySetting.facilityCategories.indexOf(tag),
        1
      );
    };

    const showInputFacilityCategories = () => {
      inputVisibleFacilityCategories.value = true;
      nextTick(() => {
        saveTagInputFacilityCategories.value.$refs.input.focus();
      });
    };

    const handleInputConfirmFacilityCategories = () => {
      let value = inputValueFacilityCategories.value;
      let propsCurrentSpaceData = props.currentSpaceData;
      if (value) {
        propsCurrentSpaceData.facilitySetting.facilityCategories.push(value);
      }
      inputVisibleFacilityCategories.value = false;
      inputValueFacilityCategories.value = "";
    };

    let inputVisiblePropertyCategories = ref(false);
    let inputValuePropertyCategories = ref("");
    const saveTagInputPropertyCategories: any = ref(null);

    const handleClosePropertyCategories = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData;
      propsCurrentSpaceData.facilitySetting.propertyCategories.splice(
        propsCurrentSpaceData.facilitySetting.propertyCategories.indexOf(tag),
        1
      );
    };

    const showInputPropertyCategories = () => {
      inputVisiblePropertyCategories.value = true;
      nextTick(() => {
        saveTagInputPropertyCategories.value.$refs.input.focus();
      });
    };

    const handleInputConfirmPropertyCategories = () => {
      let value = inputValuePropertyCategories.value;
      let propsCurrentSpaceData = props.currentSpaceData;
      if (value) {
        propsCurrentSpaceData.facilitySetting.propertyCategories.push(value);
      }
      inputVisiblePropertyCategories.value = false;
      inputValuePropertyCategories.value = "";
    };

    return {
      props,
      inputVisibleFacilityCategories,
      inputValueFacilityCategories,
      saveTagInputFacilityCategories,
      handleCloseFacilityCategories,
      showInputFacilityCategories,
      handleInputConfirmFacilityCategories,
      inputVisiblePropertyCategories,
      inputValuePropertyCategories,
      saveTagInputPropertyCategories,
      handleClosePropertyCategories,
      showInputPropertyCategories,
      handleInputConfirmPropertyCategories,
    };
  },
});
</script>

<style>
</style>