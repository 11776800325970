
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["currentSpaceData","isEdit"],
  setup(props, { attrs, emit, slots }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let spaceURL = computed(() => {
      return `https://partner.spacesgo.com/#/space/${currentSpaceGuid}`;
    });
    let spaceData: any = reactive({});
    const formRef = ref<null | HTMLFormElement>(null);
    const saveTagInputPhone: any = ref(null);
    const saveTagInputEmail: any = ref(null);
    let inputVisiblePhone = ref(false);
    let inputValuePhone = ref("");
    let inputVisibleEmail = ref(false);
    let inputValueEmail = ref("");
    let onSubmit = () => {
      console.log("submit!");
    };

    const handleClosePhone = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.contact.phones.splice(propsCurrentSpaceData.contact.phones.indexOf(tag), 1);
    };

    const showInputPhone = () => {
      inputVisiblePhone.value = true;
      nextTick(() => {
        saveTagInputPhone.value.$refs.input.focus();
      });
    };

    const handleInputConfirmPhone = () => {
      let value = inputValuePhone.value;
      let propsCurrentSpaceData = props.currentSpaceData
      if (value) {
        propsCurrentSpaceData.contact.phones.push(value);
      }
      inputVisiblePhone.value = false;
      inputValuePhone.value = "";
    };

    const handleCloseEmail = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.contact.emails.splice(propsCurrentSpaceData.contact.emails.indexOf(tag), 1);
    };

    const showInputEmail = () => {
      inputVisibleEmail.value = true;
      nextTick(() => {
        saveTagInputEmail.value.$refs.input.focus();
      });
    };

    const handleInputConfirmEmail = () => {
      let value = inputValueEmail.value;
      let propsCurrentSpaceData = props.currentSpaceData
      if (value) {
        propsCurrentSpaceData.contact.emails.push(value);
      }
      inputVisibleEmail.value = false;
      inputValueEmail.value = "";
    };

    // const init = () => {
    // };
    // init();

    return {
      spaceData,
      onSubmit,
      formRef,
      inputVisiblePhone,
      inputValuePhone,
      inputVisibleEmail,
      inputValueEmail,
      handleClosePhone,
      showInputPhone,
      saveTagInputPhone,
      handleInputConfirmPhone,
      saveTagInputEmail,
      handleCloseEmail,
      showInputEmail,
      handleInputConfirmEmail,
      spaceURL,
      props,
    };
  },
});
