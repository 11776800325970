<template>
  <div class="card">
    <div class="card-body p-10">
      <div class="row">
        <div class="col">
          <ul class="nav mb-10">
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  active
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_1"
                >基本資料</a
              >
            </li>

            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_2"
                >設施與服務</a
              >
            </li>
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                  me-1
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_3"
                >設施通用設定</a
              >
            </li>
            <li class="nav-item">
              <a
                class="
                  nav-link
                  btn btn-lg btn-color-muted btn-active btn-active-light-primary
                  fw-bolder
                  px-4
                "
                data-bs-toggle="tab"
                href="#kt_table_widget_7_tab_4"
                >QR Code</a
              >
            </li>
          </ul>
        </div>
        <div class="col d-flex justify-content-end align-items-center">
          <el-switch v-model="isEdit" active-text="開啟修改"></el-switch>
          <button
            v-if="isEdit"
            type="button"
            class="btn btn-primary ms-3"
            style="height: 45.44px"
            @click="update()"
          >
            送出
          </button>
        </div>
      </div>

      <div class="tab-content">
        <div class="tab-pane fade active show" id="kt_table_widget_7_tab_1">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <BasicInformation
              :currentSpaceData="spaceData"
              :isEdit="isEdit"
              @changeData="changeData"
            ></BasicInformation>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_2">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <FacilitiesAndServices
              :currentSpaceData="spaceData"
              :isEdit="isEdit"
            ></FacilitiesAndServices>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_3">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <FacilitySettings
              :currentSpaceData="spaceData"
              :isEdit="isEdit"
            ></FacilitySettings>
          </div>
          <!--end::Table-->
        </div>
        <div class="tab-pane fade" id="kt_table_widget_7_tab_4">
          <!--begin::Table container-->
          <div class="table-responsive">
            <!--begin::Table-->
            <SpaceQRCode></SpaceQRCode>
          </div>
          <!--end::Table-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import BasicInformation from "./components/BasicInformation.vue";
import FacilitiesAndServices from "./components/FacilitiesAndServices.vue";
import FacilitySettings from "./components/FacilitySettings.vue";
import SpaceQRCode from "./components/SpaceQRCode.vue";
import localstorage from "@/core/services/LocalstorageService";
import { updateSpace } from "@/core/services/api/spaces";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getSpacesByManager,
  updateFacilitySetting,
} from "@/core/services/api/spaces";

export default defineComponent({
  components: {
    BasicInformation,
    FacilitiesAndServices,
    FacilitySettings,
    SpaceQRCode,
  },
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let isEdit = ref(false);
    const spaceData: any = reactive({});

    const setSpaceData = () => {
      const currentSpace = localstorage.getCurrentSpaces()?.space;
      Object.assign(spaceData, currentSpace);
    };

    const changeData = (data) => {
      Object.assign(spaceData, data);
    };

    const update = async () => {
      await Swal.fire({
        title: "您確定要提交修改嗎?",
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let facilitySettingRequest = {
            space: spaceData.guid,
            facilitySetting: spaceData.facilitySetting,
          };
          await updateFacilitySetting(facilitySettingRequest);
          let updateSpaceRequest = {
            guid: spaceData.guid,
            name: spaceData.name,
            status: spaceData.status,
            images: spaceData.theme.images,
            contact: {
              phones: spaceData.contact.phones,
              emails: spaceData.contact.emails,
              website: spaceData.contact.website,
              address: spaceData.contact.address,
              googleMap: spaceData.contact.googleMap,
            },
            socialMedia: {
              facebook: spaceData.socialMedia.facebook,
              line: spaceData.socialMedia.line,
            },
            information: spaceData.information,
            description: spaceData.description,
            openingHoursDescription: spaceData.openingHoursDescription,
            usageNote: spaceData.usageNote,
          };
          await updateSpace(updateSpaceRequest).then(() => {
            getSpacesByManager().then((spaces) => {
              localstorage.saveSpaces(spaces);
              let currentSpaces: any = spaces.filter(
                (o) => o.space.guid == currentSpaceGuid!
              );
              localstorage.saveCurrentSpaces(currentSpaces[0]);
              Swal.fire("提交成功!", "", "success");
            });
          });
        }
      });
    };

    const init = () => {
      setSpaceData();
    };
    init();

    return {
      isEdit,
      changeData,
      spaceData,
      update,
    };
  },
});
</script>