<template>
  <div class="d-flex justify-content-between">
    <div class="mt-2" style="color: red">
      ※拖動下方列表以改變順序，更改完畢後請開啟修改儲存。
    </div>
    <button
      v-if="props.isEdit"
      type="button"
      class="btn btn-success btn-sm"
      data-bs-toggle="modal"
      data-bs-target="#addFacilitiesAndServices-modal"
    >
      新增設施與服務
    </button>
  </div>

  <table class="table table-striped">
    <thead class="thead">
      <tr>
        <th scope="col">圖示</th>
        <th scope="col">標題</th>
        <th scope="col">內容</th>
        <th scope="col">功能</th>
      </tr>
    </thead>
    <draggable :disabled="!props.isEdit" v-model="props.currentSpaceData.information" tag="tbody">
      <template #item="{ element, index }">
        <tr>
          <td class="d-flex align-items-center" scope="row">
            <img
              :src="getIcon(element.icon)"
              class="img-fluid"
              alt="..."
              style="max-width: 30px; max-hight: 30px"
            />
          </td>
          <td>
            <el-input
              v-model="element.title"
              :disabled="!props.isEdit"
            ></el-input>
          </td>
          <td>
            <el-input
              v-model="element.content"
              :disabled="!props.isEdit"
            ></el-input>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-link btn-color-danger"
              @click="deleteFacility(index)"
              :disabled="!props.isEdit"
            >
              刪除
            </button>
          </td>
        </tr>
      </template>
    </draggable>
  </table>
  <!-- 新增設施與服務modal -->
  <div
    class="modal fade"
    id="addFacilitiesAndServices-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">新增設施與服務</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddFacilitiesAndServices
            @addFacilitiesAndServices="addFacilitiesAndServices"
          ></AddFacilitiesAndServices>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import draggable from "vuedraggable";
import localstorage from "@/core/services/LocalstorageService";
import { getIconPath } from "@/core/utils/imagePath";
import AddFacilitiesAndServices from "./AddFacilitiesAndServices.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    draggable,
    AddFacilitiesAndServices,
  },
  props: ["currentSpaceData", "isEdit"],
  setup(props) {
    let addFacilitiesAndServicesModal;
    let spaceData: any = reactive({});
    const setSpaceData = () => {
      const currentSpace = localstorage.getCurrentSpaces()?.space;
      Object.assign(spaceData, currentSpace);
    };

    const getIcon = (name) => {
      return getIconPath(name);
    };

    const deleteFacility = (number) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.information.splice(number, 1);
    };

    const addFacilitiesAndServices = (formData) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.information.push(JSON.parse(JSON.stringify(formData)));
      addFacilitiesAndServicesModal.hide();
    };

    onMounted(() => {
      addFacilitiesAndServicesModal = new Modal(
        document.getElementById("addFacilitiesAndServices-modal")
      );
    });

    const init = () => {
      setSpaceData();
    };
    init();

    return {
      spaceData,
      getIcon,
      deleteFacility,
      props,
      addFacilitiesAndServices,
    };
  },
});
</script>

<style>
</style>