
import { defineComponent } from "vue";
import { getQrCodePath } from "@/core/utils/imagePath";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let qrCodeSrc = "";

    const setQrCode = () => {
      qrCodeSrc = getQrCodePath(
        "https://spacesgoapi.azurewebsites.net",
        "Space",
        currentSpaceGuid,
        "false"
      );
    };

    const init = () => {
      setQrCode();
    };
    init();

    return {
      qrCodeSrc,
    };
  },
});
