<template>
  <div class="row mb-5" style="width: 100%">
    <el-form
      id="kt_modal_additional_orders_list"
      @submit.prevent="onSubmit()"
      :model="props.currentSpaceData"
      ref="formRef"
      class="form"
      label-width="110px"
    >
      <div class="col-12 mb-5">
        <h1>空間資訊</h1>
      </div>
      <el-form-item label="開放搜尋">
        <el-switch
          v-model="props.currentSpaceData.status"
          active-value="Public"
          inactive-value="Private"
          :disabled="!props.isEdit"
        ></el-switch>
      </el-form-item>
      <el-form-item label="空間名稱">
        <el-input v-model="props.currentSpaceData.name" :disabled="!props.isEdit"></el-input>
      </el-form-item>
      <el-form-item label="Spaces Go Web">
        <el-input v-model="spaceURL" disabled></el-input>
      </el-form-item>
      <el-form-item label="營業時間">
        <el-input
          v-model="props.currentSpaceData.openingHoursDescription"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input
          v-model="props.currentSpaceData.contact.address"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="注意事項">
        <el-input
          v-model="props.currentSpaceData.usageNote"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="空間簡介">
        <el-input
          :disabled="!props.isEdit"
          type="textarea"
          v-model="props.currentSpaceData.description"
          :autosize="{ minRows: 4, maxRows: 6 }"
        ></el-input>
      </el-form-item>
      <div class="col-12 mb-5">
        <h1>聯絡方式</h1>
      </div>
      <el-form-item label="連絡電話">
        <el-tag
          class="me-1"
          :key="tag"
          v-for="tag in props.currentSpaceData.contact.phones"
          :closable="props.isEdit"
          :disable-transitions="false"
          @close="handleClosePhone(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisiblePhone"
          v-model="inputValuePhone"
          ref="saveTagInputPhone"
          size="small"
          @keyup.enter="handleInputConfirmPhone"
          @blur="handleInputConfirmPhone"
        >
        </el-input>
        <el-button
          v-else-if="props.isEdit"
          class="button-new-tag"
          size="small"
          @click="showInputPhone"
          >+</el-button
        >
      </el-form-item>
      <el-form-item label="電子信箱">
        <el-tag
          class="me-1"
          :key="tag"
          v-for="tag in props.currentSpaceData.contact.emails"
          :closable="props.isEdit"
          :disable-transitions="false"
          @close="handleCloseEmail(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisibleEmail"
          v-model="inputValueEmail"
          ref="saveTagInputEmail"
          size="small"
          @keyup.enter="handleInputConfirmEmail"
          @blur="handleInputConfirmEmail"
        >
        </el-input>
        <el-button
          v-else-if="props.isEdit"
          class="button-new-tag"
          size="small"
          @click="showInputEmail"
          >+</el-button
        >
      </el-form-item>
      <el-form-item label="網站">
        <el-input
          v-model="props.currentSpaceData.contact.website"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <div class="col-12 mb-5">
        <h1>社群媒體</h1>
      </div>
      <el-form-item label="Facebook">
        <el-input
          v-model="props.currentSpaceData.socialMedia.facebook"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
      <el-form-item label="Line">
        <el-input
          v-model="props.currentSpaceData.socialMedia.line"
          :disabled="!props.isEdit"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { computed, defineComponent, nextTick, reactive, ref, watch } from "vue";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  props: ["currentSpaceData","isEdit"],
  setup(props, { attrs, emit, slots }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let spaceURL = computed(() => {
      return `https://partner.spacesgo.com/#/space/${currentSpaceGuid}`;
    });
    let spaceData: any = reactive({});
    const formRef = ref<null | HTMLFormElement>(null);
    const saveTagInputPhone: any = ref(null);
    const saveTagInputEmail: any = ref(null);
    let inputVisiblePhone = ref(false);
    let inputValuePhone = ref("");
    let inputVisibleEmail = ref(false);
    let inputValueEmail = ref("");
    let onSubmit = () => {
      console.log("submit!");
    };

    const handleClosePhone = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.contact.phones.splice(propsCurrentSpaceData.contact.phones.indexOf(tag), 1);
    };

    const showInputPhone = () => {
      inputVisiblePhone.value = true;
      nextTick(() => {
        saveTagInputPhone.value.$refs.input.focus();
      });
    };

    const handleInputConfirmPhone = () => {
      let value = inputValuePhone.value;
      let propsCurrentSpaceData = props.currentSpaceData
      if (value) {
        propsCurrentSpaceData.contact.phones.push(value);
      }
      inputVisiblePhone.value = false;
      inputValuePhone.value = "";
    };

    const handleCloseEmail = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData
      propsCurrentSpaceData.contact.emails.splice(propsCurrentSpaceData.contact.emails.indexOf(tag), 1);
    };

    const showInputEmail = () => {
      inputVisibleEmail.value = true;
      nextTick(() => {
        saveTagInputEmail.value.$refs.input.focus();
      });
    };

    const handleInputConfirmEmail = () => {
      let value = inputValueEmail.value;
      let propsCurrentSpaceData = props.currentSpaceData
      if (value) {
        propsCurrentSpaceData.contact.emails.push(value);
      }
      inputVisibleEmail.value = false;
      inputValueEmail.value = "";
    };

    // const init = () => {
    // };
    // init();

    return {
      spaceData,
      onSubmit,
      formRef,
      inputVisiblePhone,
      inputValuePhone,
      inputVisibleEmail,
      inputValueEmail,
      handleClosePhone,
      showInputPhone,
      saveTagInputPhone,
      handleInputConfirmPhone,
      saveTagInputEmail,
      handleCloseEmail,
      showInputEmail,
      handleInputConfirmEmail,
      spaceURL,
      props,
    };
  },
});
</script>



