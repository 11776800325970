
import { defineComponent, nextTick, ref } from "vue";

export default defineComponent({
  props: ["currentSpaceData", "isEdit"],
  setup(props) {
    let inputVisibleFacilityCategories = ref(false);
    let inputValueFacilityCategories = ref("");
    const saveTagInputFacilityCategories: any = ref(null);

    const handleCloseFacilityCategories = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData;
      propsCurrentSpaceData.facilitySetting.facilityCategories.splice(
        propsCurrentSpaceData.facilitySetting.facilityCategories.indexOf(tag),
        1
      );
    };

    const showInputFacilityCategories = () => {
      inputVisibleFacilityCategories.value = true;
      nextTick(() => {
        saveTagInputFacilityCategories.value.$refs.input.focus();
      });
    };

    const handleInputConfirmFacilityCategories = () => {
      let value = inputValueFacilityCategories.value;
      let propsCurrentSpaceData = props.currentSpaceData;
      if (value) {
        propsCurrentSpaceData.facilitySetting.facilityCategories.push(value);
      }
      inputVisibleFacilityCategories.value = false;
      inputValueFacilityCategories.value = "";
    };

    let inputVisiblePropertyCategories = ref(false);
    let inputValuePropertyCategories = ref("");
    const saveTagInputPropertyCategories: any = ref(null);

    const handleClosePropertyCategories = (tag) => {
      let propsCurrentSpaceData = props.currentSpaceData;
      propsCurrentSpaceData.facilitySetting.propertyCategories.splice(
        propsCurrentSpaceData.facilitySetting.propertyCategories.indexOf(tag),
        1
      );
    };

    const showInputPropertyCategories = () => {
      inputVisiblePropertyCategories.value = true;
      nextTick(() => {
        saveTagInputPropertyCategories.value.$refs.input.focus();
      });
    };

    const handleInputConfirmPropertyCategories = () => {
      let value = inputValuePropertyCategories.value;
      let propsCurrentSpaceData = props.currentSpaceData;
      if (value) {
        propsCurrentSpaceData.facilitySetting.propertyCategories.push(value);
      }
      inputVisiblePropertyCategories.value = false;
      inputValuePropertyCategories.value = "";
    };

    return {
      props,
      inputVisibleFacilityCategories,
      inputValueFacilityCategories,
      saveTagInputFacilityCategories,
      handleCloseFacilityCategories,
      showInputFacilityCategories,
      handleInputConfirmFacilityCategories,
      inputVisiblePropertyCategories,
      inputValuePropertyCategories,
      saveTagInputPropertyCategories,
      handleClosePropertyCategories,
      showInputPropertyCategories,
      handleInputConfirmPropertyCategories,
    };
  },
});
